import React, { useEffect } from "react";
import GetAPI from "../../utilities/GetAPI";
import Loader, { MiniLoader } from "../../components/Loader";
import Layout from "../../components/Layout";
import { inputStyle, labelStyle } from "../../utilities/Input";
import { useState } from "react";
import { info_toaster } from "../../utilities/Toaster";
import { PutAPI } from "../../utilities/PutAPI";

export default function PrivacyPolicy() {
  const { data, reFetch } = GetAPI("privacypolicy");
  const [loader, setLoader] = useState(false);
  const [policy, setPolicy] = useState("");
  const updatePolicyFunc = async (e) => {
    e.preventDefault();
    if (policy === "") {
      info_toaster("Please enter Privacy Policy");
    } else {
      setLoader(true);
      let res = await PutAPI("updateprivacypolicy", {
        content: policy,
      });
      if (res?.data?.status === "1") {
        reFetch();
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    const privacyPolicy = data?.data && data?.data?.value;
    setPolicy(privacyPolicy);
  }, [data]);
  return data.length === 0 ? (
    <Loader />
  ) : (
    <Layout
      title="Privacy Policy"
      content={
        <form
          onSubmit={updatePolicyFunc}
          className="bg-white p-12 rounded-xl space-y-5 min-h-[calc(100vh-240px)] flex flex-col justify-between relative"
        >
          {loader ? (
            <MiniLoader />
          ) : (
            <>
              <div className="w-1/2">
                <div className="space-y-2">
                  <label className={labelStyle} htmlFor="policy">
                    {data?.data?.title}
                  </label>
                  <textarea
                    value={policy}
                    onChange={(e) => setPolicy(e.target.value)}
                    name="policy"
                    id="policy"
                    rows={8}
                    placeholder="Enter Privacy Policy"
                    className={inputStyle}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-end gap-x-2">
                <button
                  type="button"
                  onClick={() => setPolicy(data?.data?.value)}
                  className="py-3 w-40 rounded font-medium text-sm text-themeBlue border border-themeBlue hover:bg-themeBlue hover:text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loader}
                  className="py-3 w-40 rounded font-medium text-sm text-white bg-themeBlue border border-themeBlue hover:bg-transparent hover:text-themeBlue"
                >
                  Save
                </button>
              </div>
            </>
          )}
        </form>
      }
    />
  );
}
